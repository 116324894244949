import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import _ from 'lodash';
import { Layout } from '../components';

const Scholarship = ({ data }) => {
    const {
        body, heading, subHeading, metaTitle, metaDescription,
    } = _.head(data.graphCMS.scholarshipPages);
    const { scholarshipWinnerPages } = data.graphCMS;

    return (
        <Layout metaTitle={metaTitle} metaDescription={metaDescription}>
            <h1>{heading}</h1>
            <p>{subHeading}</p>
            <ReactMarkdown source={body} escapeHtml={false} />
            <h2>Previous Winners</h2>
            {
                _.map(scholarshipWinnerPages, ({ slug, winnerName, city }) => {
                    // need to check for a state for the Washington, DC case since it doesn't have one
                    const stateSlug = _.get(city.state, 'slug');

                    return (
                        <div key={slug}>
                            <Link to={`/scholarship/${slug}/`}>
                                {`${winnerName} - ${city.name}${stateSlug ? `, ${_.toUpper(city.state.slug)}` : ''}`}
                            </Link>
                        </div>
                    );
                })
            }
        </Layout>
    );
};

export const pageQuery = graphql`
    query ScholarshipPageQuery {
        graphCMS {
            scholarshipPages(last: 1){
                id
                body
                heading
                metaTitle
                metaDescription
                subHeading
            }
            scholarshipWinnerPages {
                slug
                winnerName
                city {
                    name
                    state {
                        slug
                    }
                }
            }
        }
    }
`;

Scholarship.propTypes = {
    data: PropTypes.shape({
        graphCMS: PropTypes.shape({
            scholarshipPages: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.string,
                body: PropTypes.string,
                heading: PropTypes.string,
                metaTitle: PropTypes.string,
                metaDescription: PropTypes.string,
                subHeading: PropTypes.string,
            })),
            scholarshipWinnerPages: PropTypes.arrayOf(PropTypes.shape({
                slug: PropTypes.string,
                winnerName: PropTypes.string,
                city: PropTypes.shape({
                    name: PropTypes.string,
                    state: PropTypes.shape({
                        slug: PropTypes.string,
                    }),
                }),
            })),
        }).isRequired,
    }).isRequired,
};

export default Scholarship;
